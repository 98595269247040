<template>
  <div class="v-password-recovery">
    <div class="v-password-recovery__content">
      <div class="password-recovery g-content">
        <div class="password-recovery__box">
          <div class="password-recovery__box__content">
            <div class="title-signin">
              <div class="title-signin__title">
                <red-decorate-title
                  title="PASSWORD RECOVERY"
                  size="large"
                  :placement="{
                    bottom: '0px',
                    right: '-5px',
                  }"
                />
              </div>
            </div>

            <div class="signup-form-wrap">
              <n-form :label-width="80" :model="model" :rules="rules" :size="size" ref="formRef" label-placement="top">
                <n-grid :cols="12" :x-gap="20">
                  <n-form-item-gi :span="12" label="電子信箱" path="email">
                    <n-input placeholder="Input" v-model:value="model.phone" />
                  </n-form-item-gi>
                </n-grid>
              </n-form>
            </div>

            <div class="sign-up-confirm">
              <div class="sign-up-confirm__btn-wrap">
                <mi-button>Reset password</mi-button>
              </div>
            </div>

            <p class="already-member">
              Already a member?
              <router-link :to="{ name: 'sign-in' }">
                <span class="already-member__underline">Sign in</span>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import {
  NForm, NInput, NFormItemGi, NGrid,
} from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';

export default defineComponent({
  name: 'SignIn',
  components: {
    MiButton,
    RedDecorateTitle,
    NForm,
    NInput,
    NGrid,
    NFormItemGi,
  },
  setup() {
    const formRef = ref(null);
    // const message = useMessage();
    return {
      formRef,
      size: ref('medium'),

      model: ref({
        email: '',
      }),

      rules: {
        email: {
          required: true,
          trigger: ['input'],
        },
      },

      handleValidateClick() {
        formRef.value.validate((errors) => {
          if (!errors) {
            // message.success('Valid');
          } else {
            console.log(errors);
            // message.error('Invalid');
          }
        });
      },
    };
  },
});
</script>
<style lang="scss" scoped>
.v-password-recovery {
  width: 100%;
  height: 100%;

  &__content {
    width: 100%;
    height: 100%;
    flex: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('~@/assets/profile/bg_signin.png');
    position: relative;
  }
}

.password-recovery {
  width: 100%;
  height: 100%;
  position: relative;
  @include flex(center);

  &__box {
    width: 100%;
    max-width: 660px;
    background-color: $c-white;

    &__content {
      @include padding(30px 20px);
    }
  }
}

.already-member {
  @include font-style($c-assist3, 14, 600, 0.28px, 24px);
  text-align: center;
  margin-top: 20px;

  &__underline {
    color: $c-assist3;
    text-decoration: underline;
  }
}

.title-signin {
  @include flex(space-between, flex-end);

  &__title {
  }

  &__signin {
    @include font-style($c-assist3, 14, bold, 0.28px, 24px);

    &__underline {
      color: $c-assist3;
      text-decoration: underline;
    }
  }

  &__signin.show-mobile {
    margin-top: 20px;
    text-align: center;
  }
}

.keep-forget {
  @include flex(space-between);

  &__keep {
    @include font-style($c-assist6, 16, normal, 0.8px, 20px);
  }

  &__forget {
    @include font-style($c-assist3, 14, 600, 0.28px, 24px);
    text-decoration: underline;
  }
}

.signup-form-wrap {
  width: 100%;
  margin-top: 60px;

  // ::v-deep .n-form-item-label {
  //   @include font-style($c-assist3, 16, normal, 0.32px, 24px);
  // }
}

.sign-up {
  margin-top: 50px;
  flex: none;
  width: 50%;

  &__content {
    width: 440px;
    margin: 0 auto;
  }
}

.sign-up-confirm {
  margin-top: 50px;

  &__btn-wrap {
    text-align: center;
  }
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $mobile) {
  .password-recovery {
    &__box {
      &__content {
        @include padding(60px 110px);
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .signup-form-wrap {
    width: 100%;
    margin-top: 60px;
  }
}
</style>
